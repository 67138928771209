import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquesta espècie forma part d’un grup de Rúsules de coloracions roges o porpres, i a més molt picants. Consta d’un capell de 4 a 10 cm de diàmetre, primer convex per passar a aplanat i lleugerament deprimit al centre amb el marge aixecat. El color del capell contrasta amb el blanc o crema de les làmines. Són atapeïdes i suaument decurrents. El peu és curt i fort, de 2 a 6,5 cm x 1 a 2 cm, cilíndric, una mica corbat, de color blanc amb tons liliacis i vermellosos. Les espores són blanques amb tons ocres en massa, tenen berrugues, de 8-9 x 6,5-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      